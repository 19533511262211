import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {Typography,Fab,Toolbar,TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
// import MobileStepper from '@material-ui/core/MobileStepper';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
//import DuplicateIcon from '@material-ui/icons/ControlPointDuplicate';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';
import ReactTable from './shares/react_table';

//import {Link} from 'react-router-dom';

import classNames from 'classnames';

import theme,{styles} from '../layout/theme';
import Utils from '../api/api';

// import WebConfig from '../api/config';

// var config = WebConfig.production;
// if(window.location.hostname==='localhost'){
//   config = WebConfig.development;
// }


export class ContentPageList extends Component {
  constructor(props){
    super(props);
    this.state = {
      // data:[],
      // failed:false,
      // message:'',
      // activeStep:0,
      // totalSteps:1,
      // lastId:0,
      // firstId:0,
      // ranges: [],
      // keyword:''
      keyword: '',
      searchClick: false
    }
  }
  // componentDidMount(){
  //   var $this = this;
  //   Utils.contentpages(1,config.itemPerPage,$this.state.firstId + ',' + $this.state.lastId,'',1,function(data){
  //     //console.log(data);
  //     if(data.status=='success'&&data.results.length){
  //       //console.log(data);
  //       var first = data.results[0]._id;
  //       var last = data.results[data.results.length-1]._id;
  //       $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:last,firstId:first},function(){
  //         var arr = $this.state.ranges;
  //         for(var i=0,len=$this.state.totalSteps;i<len;i++){
  //           arr.push({});
  //         }
  //         arr[0] = first + ',' + last;
  //         $this.setState({ranges:arr});
  //       });
  //     }
  //   });
  // }
  handleSearch(e){
    //console.log(e.target);
    // var $this = this;
    // Utils.contentpages(1,config.itemPerPage,'0,0',$this.state.keyword,1,function(data){
    //   if(data.status=='success'&&data.results.length){
    //     var first = data.results[0]._id;
    //     var last = data.results[data.results.length-1]._id;
    //     $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:last,firstId:first,activeStep:0},function(){
    //       var arr = $this.state.ranges;
    //       for(var i=0,len=$this.state.totalSteps;i<len;i++){
    //         arr.push('');
    //       }
    //       arr[0] = first + ',' + last;
    //       $this.setState({ranges:arr});
    //     });
    //   }
    // });
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }
  // onStepNext=()=>{
  //   var $this = this;
  //   this.setState({
  //     activeStep: $this.state.activeStep + 1
  //   },function(){
  //     Utils.contentpages($this.state.activeStep,config.itemPerPage,$this.state.firstId +','+$this.state.lastId,$this.state.keyword,1,function(data){
  //       if(data.status=='success'&&data.results.length){
  //         var first = data.results[0]._id;
  //         var last = data.results[data.results.length-1]._id;
  //         var arr = $this.state.ranges;
  //         $this.setState({data:data.results,lastId:last,firstId:first},function(){
  //           arr[$this.state.activeStep] = first + ',' + last;
  //           $this.setState({ranges:arr});
  //         });
  //       }
  //     });
  //   });
  // }
  // onStepBack=()=>{
  //   var $this = this;
  //   this.setState({
  //     activeStep: this.state.activeStep - 1
  //   },function(){
  //     console.log($this.state.ranges[$this.state.activeStep]);
  //     Utils.contentpages($this.state.activeStep,config.itemPerPage,$this.state.ranges[$this.state.activeStep],$this.state.keyword,0,function(data){
  //       if(data.status=='success'&&data.results.length){
  //         //console.log(data);
  //         var last = data.results[data.results.length-1]._id;
  //         $this.setState({data:data.results,lastId:last});
  //       }
  //     });
  //   });
  // }
  handleAdd(){
    this.context.router.history.push('/pages/add');
  }
  // handleEdit(obj){
  //   this.context.router.history.push('/pages/edit/'+obj._id);
  // }
  // handleDelete(obj){
  //   var $this = this;
  //   Utils.contentpage_delete(obj,function(res){
  //     //console.log(res);
  //     if(res.status=='success'){
  //       let filter='';
  //       if($this.state.keyword!==''){
  //         filter=$this.state.keyword;
  //       }
  //       Utils.contentpages(1,config.itemPerPage,'0,0',filter,1,function(data){
  //         if(data.status=='success'&&data.results.length){
  //           var first = data.results[0]._id;
  //           var last = data.results[data.results.length-1]._id;
  //           $this.setState({data:data.results,totalSteps:Math.ceil(data.total/config.itemPerPage),lastId:last,firstId:first,activeStep:0},function(){
  //             var arr = [];
  //             for(var i=0,len=$this.state.totalSteps;i<len;i++){
  //               arr.push('');
  //             }
  //             arr[0] = first + ',' + last;
  //             $this.setState({ranges:arr});
  //           });
  //         }
  //       });
  //     }else{
  //       $this.setState({failed:true,message:res.message});
  //     }
  //   });
  // }
  // handleDuplicate(obj){
  //   var $this = this;
  //   Utils.contentpage(obj._id,function(data){
  //     if(data.status=='success'&&data.results!==null){
  //       var obj = {}, item = data.results;
  //       for(var property in item) {
  //         if(item.hasOwnProperty(property)) {
  //           if(property!=='_id'){
  //             obj[property] = item[property];
  //           }
  //         }
  //       }
  //       obj.titleVN =  item.titleVN + ' - ' + Date.now();
  //       obj.titleEN =  item.titleEN + ' - ' + Date.now();
  //       obj.titleKR =  item.titleKR + ' - ' + Date.now();
  //       obj.url = Utils.getFriendlyURL(obj.titleVN);
  //       Utils.contentpage_add(obj, function(res) {
  //         if (res.status == 'success') {
  //           //$this.context.router.history.push('/pages/');
  //           //console.log(res);
  //           $this.context.router.history.push('/pages/edit/'+res.data.insertedIds[0]);
  //         } else {
  //           $this.setState({failed: true, message: JSON.stringify(res.message)});
  //         }
  //       });
  //     }
  //   });
  // }
  render(){
    const classes = this.props.classes;
    var source = {
      thead: [{title:'Tiêu đề',value:'name'}, { title: 'URL', value: 'url' }],
      tbody: function (n) {
        return ({
          _id: n._id,
          name: n.name,
          url: (<div>{n.urlVN}<br/>{n.urlEN}</div>),
        });
      }
    };
    return(
      <div className="content-page-list">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục nội dung</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
            <FormGroup className={classes.formRowToolbar}>
              <TextField type="search" id="keyword" label="Tìm kiếm" className={classes.textField + ' form-control-no-bottom'} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}/>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={(e)=>this.handleSearch(e)}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiList='contentpages' apiSingle='contentpage' apiTable='pages' apiDuplicate={true} router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} >
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

ContentPageList.propTypes = {
  classes: PropTypes.object.isRequired,
};
ContentPageList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ContentPageList);
