import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {TextField, Typography, Button, FormLabel,Toolbar,Switch,FormControlLabel,Tabs, Tab } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Media from './media';
import TabContainer from './shares/react_tab_container';
import language from '../layout/language';


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/third_party/font_awesome.min.js';
import 'froala-editor/js/third_party/ionicons.min.js';


// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
import 'froala-editor/css/third_party/font_awesome.min.css';
import 'froala-editor/css/third_party/ionicons.min.css';

import FroalaEditor from 'react-froala-wysiwyg';


import classNames from 'classnames';
import Utils from '../api/api';
import theme, {styles} from '../layout/theme';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ContentPageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners:[],
      contentpage: {
        name: '',
        urlVN: '',
        urlEN: '',
        banner: '',
        titleVN: '',
        detailVN: '',
        descriptionVN: '',
        titleEN: '',
        detailEN: '',
        descriptionEN: '',
        // titleKR: '',
        // detailKR: '',
        // descriptionKR: '',
        og: {
          titleVN: '',
          titleEN: '',
          typeVN: '',
          typeEN: '',
          urlVN: '',
          urlEN:'',
          imageVN:'',
          imageEN:''
        },
        redirect: '' ,
        isComingSoon: false,
        isDisabled: false,
        reversion: []
      },
      error: {
        vn: false,
        en: false,
        //kr: false,
        name_error: false,
        urlVN_error: false,
        urlEN_error: false,
        titleVN_error: false,
        detailVN_error: false,
        titleEN_error: false,
        detailEN_error: false,
        //titleKR_error: false,
        //detailKR_error: false,
      },
      failed: false,
      message: '',
      isUpdate: false,
      tab: 0,
      options: {'empty':'empty'},
      openMedia: false,
      currentFlag: ''
    }
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData('banners','all',100,'0,0',null,1,function(data) {
      //console.log(data);
      if (data.status === 'success') {
        $this.setState({banners: data.results});
      }
    });

    Utils.getBlockContents((options) => {
      $this.setState({options:options});
    });

    if (params.id !== undefined) {
      $this.setState({
        isUpdate: true
      }, function() {
        Utils.getSingleData('pages',params.id, function(res) {
          if (res.status == 'success' && res.results!==undefined) {
            var data = {}, item = res.results;
            for(var property in item) {
              if(item.hasOwnProperty(property)) {
                data[property] = item[property];
              }
            }
            data._id = item._id;
            if(data.urlVN===null) data.urlVN='';
            if(data.urlEN===null) data.urlEN='';
            if(data.descriptionVN===null) data.descriptionVN='';
            if(data.descriptionEN===null) data.descriptionEN='';
            if(data.og===null||data.og===undefined) {
              data.og = {
                titleVN: '',
                titleEN: '',
                typeVN: '',
                typeEN: '',
                urlVN: '',
                urlEN:'',
                imageVN:'',
                imageEN:''
              }
            }
            if(data.redirect===null||data.redirect===undefined) data.redirect = '';
            if(data.isComingSoon===null||data.isComingSoon===undefined) data.isComingSoon = false;
            if(data.isDisabled===null||data.isDisabled===undefined) data.isDisabled = false;
            if(data.reversion===null||data.reversion===undefined) data.reversion = [];

            if(data.reversion.length===0) {
              data.reversion.push({
                record: {
                  name: data.name,
                  urlVN: data.urlVN,
                  urlEN: data.urlEN,
                  banner: data.banner,
                  titleVN: data.titleVN,
                  detailVN: data.detailVN,
                  descriptionVN: data.descriptionVN,
                  titleEN: data.titleEN,
                  detailEN: data.detailEN,
                  descriptionEN: data.descriptionEN,
                  og: {
                    titleVN: data.og.titleVN,
                    titleEN: data.og.titleEN,
                    typeVN: data.og.typeVN,
                    typeEN: data.og.typeEN,
                    urlVN: data.og.urlVN,
                    urlEN:data.og.urlEN,
                    imageVN:data.og.imageVN,
                    imageEN:data.og.imageEN
                  },
                  redirect: data.redirect,
                  isComingSoon: data.isComingSoon,
                  isDisabled: data.isDisabled,
                },
                active: true,
                date: new Date()
              });
            }
            //console.log(data);
            $this.setState({contentpage: data});
          }
        });
      });
    }


  }
  onEditorStateChange(field,value){
    var contentpage = this.state.contentpage,
        error = this.state.error;
    contentpage[field] = value;
    error[field+'_error'] = value=='';
    this.setState({contentpage:contentpage,error:error});

  }
  handleFieldChange(e,flag) {
    var $this = this,
        contentpage = this.state.contentpage;

    if(e.target.type==='checkbox') {
      contentpage[e.target.id] = e.target.checked;
    } else {
      contentpage[e.target.id] = e.target.value;
    }

    this.setState({contentpage:contentpage},function(){
      console.log($this.state);
    });
  }
  handleValidate(e,flag) {
    var $this = this,
        contentpage = this.state.contentpage,
        obj = this.state.error;

    contentpage[e.target.id] = e.target.value;

    if(e.target.id==='name'){
      var url = Utils.getFriendlyURL(e.target.value);
      contentpage.urlVN = url;
      contentpage.urlEN = url;
    }
    //console.log(e.target.id);
    var p = e.target.id + '_error';
    //console.log(p);
    if (e.target.value.trim() == '' || e.target.value == null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;


    this.setState({contentpage: contentpage, error:obj}, () => {
      if(!$this.state.error.titleVN_error && !$this.state.error.detailVN_error){
        obj['vn'] = false;
      }else{
        obj['vn'] = true;
      }
      if(!$this.state.error.titleEN_error && !$this.state.error.detailEN_error){
        obj['en'] = false;
      }else{
        obj['en'] = true;
      }
      // if(!$this.state.error.titleKR_error && !$this.state.error.detailKR_error){
      //   obj['kr'] = false;
      // }else{
      //   obj['kr'] = true;
      // }
      $this.setState({error:obj});
    });
  }
  _makeReversion(obj) {
    let rev = {};
    for(var property in obj) {
      if(obj.hasOwnProperty(property)&&property!=='reversion') {
        rev[property] = obj[property];
      }
    }
    return {record:rev,active:true,date:new Date()};
  }
  _save(){
    var $this = this,
        data = $this.state.contentpage;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      data.reversion.map(x=>{
        x.active = false;
      });
      data.reversion.push($this._makeReversion(data));
      // console.log(data);
      Utils._update('contentpage',data, function(res) {
        console.log(res);
        if (res.status === 'success') {
          $this.context.router.history.push('/pages/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    } else {
      Utils._add('contentpage',data, function(res) {

        if (res.status === 'success') {
          $this.context.router.history.push('/pages/');
        } else {
          $this.setState({failed: true, message: JSON.stringify(res.message)});
        }
      });
    }
  }
  handleSave(e,flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({isUpdate: true})
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch(flag){
        case 'vn':
          obj['titleVN_error'] = this.state.contentpage.titleVN === '';
          obj['detailVN_error'] = this.state.contentpage.detailVN === '';
          obj['vn'] = obj['titleVN_error'] || obj['detailVN_error'];
          this.setState({error:obj},function(){
            //console.log($this.state.error[flag]);
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        case 'en':
          obj['titleEN_error'] = this.state.contentpage.titleEN === '';
          obj['detailEN_error'] = this.state.contentpage.detailEN === '';
          obj['en'] = obj['titleEN_error'] || obj['detailEN_error'];
          this.setState({error:obj},function(){
            if(!$this.state.error[flag]) $this._save();
          });
          break;
        // case 'kr':
        //   obj['titleKR_error'] = this.state.contentpage.titleKR === '';
        //   obj['detailKR_error'] = this.state.contentpage.detailKR === '';
        //   obj['kr'] = obj['titleKR_error'] || obj['detailKR_error'];
        //   this.setState({error:obj},function(){
        //     if(!$this.state.error[flag]) $this._save();
        //   });
        //   break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push('/pages/');
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab:value });
  }
  handleCopy(){
    var data = this.state.contentpage;
    data.titleEN = data.titleVN;
    data.descriptionEN = data.descriptionVN;
    data.detailEN = data.detailVN
    data.og.titleEN = data.og.titleVN;
    data.og.typeEN = data.og.typeVN;
    data.og.urlEN = data.og.urlVN;
    data.og.imgEN = data.og.imgVN;
    // data.titleKR = data.titleVN;
    // data.descriptionKR = data.descriptionVN;
    // data.detailKR = data.detailVN;
    this.setState({
      contentpage: data
    })
  }
  handleClickOpen = (flag) => {
    this.setState({ openMedia: true,currentFlag:flag });
  };
  handleClose = () => {
    this.setState({ openMedia: false,currentFlag:'' });
  };
  selectImg(value){
    var $this = this,
        c = $this.state.contentpage;
    c.og['image'+$this.state.currentFlag] = value;
    this.setState({contentpage:c},function(){
      $this.handleClose();
    });
  }
  handleOpenMeta(e) {
    var $this = this,
        contentpage = $this.state.contentpage;
    contentpage.og[e.target.id.replace('og_','')] = e.target.value;
    this.setState({contentpage:contentpage});
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const contentpage = this.state.contentpage;

    const editorConfig = Utils.setupEditor('others',this.state.options);

    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Thêm/Sửa nội dung</Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        <form className={classes.form} noValidate>
          <Typography type="body2" className={classNames(classes.error, !this.state.error.vn && classes.hide)}>{language.VN.error.require}</Typography>
          <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
          <TextField id="name" label="Tên page" className={classes.textField} value={contentpage.name} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.name_error} margin="normal"/>
          <TextField id="urlVN" label="URL - VN" className={classes.textField} value={contentpage.urlVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.url_error} margin="normal"/>
          <TextField id="urlEN" label="URL - EN" className={classes.textField} value={contentpage.urlEN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.url_error} margin="normal"/>
          <FormGroup className={classes.formRow}>
            <Typography className={classes.label} type="caption">Banner</Typography>
            <select id="banner" className={'select ' + classes.select} value={contentpage.banner} onChange={(event) => this.handleFieldChange(event)}>
              <option value="" key={0}>...</option>
              {this.state.banners.map((item, index) => (
                <option key={index + 1} value={item._id}>{item.name}</option>
              ))}
            </select>
          </FormGroup>
          <TextField id="redirect" label="Redirect (Ưu tiên 3)" className={classes.textField} value={contentpage.redirect} onChange={(event) => this.handleFieldChange(event,'vn')} margin="normal"/>
          <FormGroup className={classes.formRow}>
            <FormControlLabel
              control={
                <Switch
                  id="isComingSoon"
                  checked={contentpage.isComingSoon === 'true' || contentpage.isComingSoon === true}
                  onChange={(event)=>this.handleFieldChange(event,'vn')}
                />
              }
              label="Chuyển về Coming soon  (Ưu tiên 2)"
            />
            <FormControlLabel
              control={
                <Switch
                  id="isDisabled"
                  checked={contentpage.isDisabled === 'true' || contentpage.isDisabled === true}
                  onChange={(event)=>this.handleFieldChange(event,'vn')}
                  value={true}
                />
              }
              label="Chuyển về 404  (Ưu tiên 1)"
            />
          </FormGroup>
        </form>
        {value === 0 && <TabContainer>
          <form className={classes.form} noValidate>
            <Button variant="contained" color="secondary" aria-label="copy" onClick={()=>this.handleCopy()}>Copy to [EN]</Button>
            <TextField id="titleVN" label={language.VN.title} className={classes.textField} value={contentpage.titleVN} onChange={(event) => this.handleValidate(event,'vn')} onBlur={(event) => this.handleValidate(event,'vn')} required={true} error={this.state.error.titleVN_error} margin="normal"/>
            <TextField id="descriptionVN" label="Mete Description - SEO" className={classes.textField} value={contentpage.descriptionVN} onChange={(event) => this.handleFieldChange(event,'vn')} multiline={true} margin="normal"/>
            <FormGroup className={classes.formRow}>
              <FormLabel>Meta Open Graph:</FormLabel>
              <TextField id="og_titleVN" label='og:title' className={classes.textField} value={contentpage.og.titleVN} onChange={(event) => this.handleOpenMeta(event)} onBlur={(event) => this.handleOpenMeta(event)} margin="normal"/>
              <TextField id="og_typeVN" label='og:type' className={classes.textField} value={contentpage.og.typeVN} onChange={(event) => this.handleOpenMeta(event)} onBlur={(event) => this.handleOpenMeta(event)} margin="normal"/>
              <TextField id="og_urlVN" label='og:url' className={classes.textField} value={contentpage.og.urlVN} onChange={(event) => this.handleOpenMeta(event)} onBlur={(event) => this.handleOpenMeta(event)} margin="normal"/>
              <TextField id="og_imageVN" label="og:image" className={classes.textField} value={contentpage.og.imageVN} onClick={()=>this.handleClickOpen('VN')} onChange={(event) => this.handleOpenMeta(event)} margin="normal"/>
            </FormGroup>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailVN_error}>{language.VN.detail}</FormLabel>
            {
              this.state.options['empty']!=='empty' && (
                <FroalaEditor
                  tag='textarea'
                  config={editorConfig}
                  model={contentpage.detailVN}
                  onModelChange={(value)=>this.onEditorStateChange('detailVN',value)}
                />
              )
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'vn')}>{language.VN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {value === 1 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.en && classes.hide)}>{language.EN.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleEN" label={language.EN.title} className={classes.textField} value={contentpage.titleEN} onChange={(event) => this.handleValidate(event,'en')} onBlur={(event) => this.handleValidate(event,'en')} required={true} error={this.state.error.titleEN_error} margin="normal"/>
            <TextField id="descriptionEN" label="Mete Description - SEO" className={classes.textField} value={contentpage.descriptionEN} onChange={(event) => this.handleFieldChange(event,'en')} multiline={true} margin="normal"/>
            <FormGroup>
              <FormLabel>Meta Open Graph:</FormLabel>
              <TextField id="og_titleEN" label='og:title' className={classes.textField} value={contentpage.og.titleVN} onChange={(event) => this.handleOpenMeta(event)} onBlur={(event) => this.handleOpenMeta(event)} margin="normal"/>
              <TextField id="og_typeEN" label='og:type' className={classes.textField} value={contentpage.og.typeVN} onChange={(event) => this.handleOpenMeta(event)} onBlur={(event) => this.handleOpenMeta(event)} margin="normal"/>
              <TextField id="og_urlEN" label='og:url' className={classes.textField} value={contentpage.og.urlVN} onChange={(event) => this.handleOpenMeta(event)} onBlur={(event) => this.handleOpenMeta(event)} margin="normal"/>
              <TextField id="og_imageEN" label="og:image" className={classes.textField} value={contentpage.og.imageVN} onClick={()=>this.handleClickOpen('EN')} onChange={(event) => this.handleOpenMeta(event)} margin="normal"/>
            </FormGroup>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailEN_error}>{language.EN.detail}</FormLabel>
            {
              this.state.options['empty']!=='empty' && (
                <FroalaEditor
                  tag='textarea'
                  config={editorConfig}
                  model={contentpage.detailEN}
                  onModelChange={(value)=>this.onEditorStateChange('detailEN',value)}
                />
              )
            }
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.EN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'en')}>{language.EN.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>}
        {/* {value === 2 && <TabContainer>
          <form className={classes.form} noValidate>
            <Typography type="body2" className={classNames(classes.error, !this.state.error.kr && classes.hide)}>{language.KR.error.require}</Typography>
            <Typography type="body2" className={classNames(classes.error, !this.state.failed && classes.hide)}>{this.state.message}</Typography>
            <TextField id="titleKR" label={language.KR.title} className={classes.textField} value={contentpage.titleKR} onChange={(event) => this.handleValidate(event,'kr')} onBlur={(event) => this.handleValidate(event,'kr')} required={true} error={this.state.error.titleKR_error} margin="normal"/>
            <TextField id="descriptionKR" label="Mete Description - SEO" className={classes.textField} value={contentpage.descriptionKR} onChange={(event) => this.handleFieldChange(event,'kr')} multiline={true} margin="normal"/>
            <FormLabel className={classes.label_shrink} required={true} error={this.state.error.detailKR_error}>{language.KR.detail}</FormLabel>
            <FroalaEditor
              tag='textarea'
              config={editorConfig}
              model={contentpage.detailKR}
              onModelChange={(value)=>this.onEditorStateChange('detailKR',value)}
            />
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={(event) => this.handleCancel(event)}>{language.KR.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={(event) => this.handleSave(event,'kr')}>{language.KR.btnSave}</Button>
            </FormGroup>
          </form>
        </TabContainer>} */}
        <Dialog
          fullScreen
          open={this.state.openMedia}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <div className={classes.dialogMedia}>
            <FormGroup row className={classes.formRow}>
              <Button color="default" onClick={this.handleClose}>{language.VN.btnCancel}</Button>
              <Button variant="contained" color="primary" onClick={this.handleClose}>{language.VN.btnSave}</Button>
            </FormGroup>
            <Media onSelectChange={(value)=>this.selectImg(value)}/>
          </div>
        </Dialog>
      </div>
    )
  }
}

ContentPageDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
ContentPageDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(ContentPageDetail);
